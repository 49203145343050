@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Particles {
	.Particles-particleWrapper {
		position: absolute;
		@include wriggle(4s);
		.Particles-particle {
			@include rise(3s);
			@include opacity(0);
			.Particles-particleImage {
				@include fadeOut(3s);
				width: 1em;
				height: 1em;
				border-radius: 1em;
				background-color: white;
			}
		}
	}
}


