@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	height: 100%;
	@include flex('space-between', 'center', 'column');
	
	.LandingPage-logoWrapper {
		@include flex('space-between', 'center');
		width: 100%;

		.LandingPage-logo {
			width: 40em;
			height: 5em;
			margin-top: 2.6em;
			background-image: url(../../assets/images/logos/logo.svg);
			background-size: contain;
		}

		.LandingPage-logoAbsalon {
			width: 10em;
			height: 4em;
			margin-top: 2.6em;
			margin-left: 4em;
			background-image: url(../../assets/images/logos/logo-absalon.svg);
			background-size: contain;
		}
		.LandingPage-logoGameLab {
			cursor: pointer;
			height: 2em;
			width: 10em;
			margin-top: 2.6em;
			margin-right: 4em;
			background-image: url(../../assets/images/logos/logo-gamelab.svg);
			background-size: contain;
		}
	}

	.LandingPage-bottomPortion {
		@include flex('', 'center', 'column');
		margin-bottom: 12.67em;
		.LandingPage-textDialogue {
			@include flex('center', 'center');
			background-color: $beigeLight;
			width: 34.8em;
			height: 8.8em;
			border-radius: 0.5em;
			border-bottom-style: solid;
			border-bottom-width: 0.24em;
			border-bottom-color: $beigeDark;

			@include box-shadow(0, 0.14em, 0.2em, 0em, rgba(black, 0.2));
			margin-bottom: 2.7em;

			.LandingPage-text {
				width: 29em;
				text-align: center;
				color: $textDark;
			}
		}
	}
}
