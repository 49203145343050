@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	width: 100%;
	height: 100%;

	&.tablet {
		font-size: calc(20 * ((100vw / 1025)));
	}

	.Game-infoIcon {
		position: absolute;
		background-image: url(../../assets/images/icons/icon-info-2.svg);
		background-size: contain;
		width: 2.35em;
		height: 2.55em;
		right: 1.4em;
		top: 1.4em;
		cursor: pointer;
		&:hover {
			@include scale(1.2);
		}
	}
	
	.Game-component {
		@include flex('','center','column');
		height: 100%;
	}

	.Game-leftUi {
		position: absolute;
		top: 1.5em;
		left: 1.5em;
	}
}