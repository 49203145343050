@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CombinedResult {
	&:before {
		content: '';
		position: absolute;

		left: 0;
		top: -1em;
		border-top-style: solid;
		width: 100%;
		border-top-width: 0.2em;
		color: $greenLight;
	}

	margin-top: 1.5em;

	.CombinedResult-title {
		span {
			font-weight: 700;
			font-size: 1.14em;
			color: $textDark;
		}
	}	
	
	.CombinedResult-completedMissionsWrapper {
		position: relative;

		padding: 0.5em;
		padding-top: 0.2em;
		margin-bottom: 0.5em;
	
		background-color: $beigeLight;
		border-radius: 0.5em;

		.CombinedResult-completedMissions {
			@include flex('center', 'center');
				
			.CombinedResult-completedMission {
				@include flex('center', 'center', 'column');
				position: relative;
				margin: 0.25em;
				margin-top: 0.5em;
				margin-bottom: 0;
				margin-left: 0.5em;
				margin-right: 0.5em;

				&:last-child {
					margin-right: 0;
				}
				&:first-child {
					margin-left: 0;
				}

				.CombinedResult-completedMissionIcon {
					width: 4.76em;
					height: 4.76em;
					background-size: contain;
					@include opacity(0.3);
					&.cheap {
						background-image: url(../../../assets/images/icons/icon-badgePrice.svg);
						&.completed {
							@include opacity(1);
						}
					}
					&.climate {
						background-image: url(../../../assets/images/icons/icon-badgeCo2.svg);
						&.completed {
							@include opacity(1);
						}
					}
					&.tasty {
						background-image: url(../../../assets/images/icons/icon-badgeTaste.svg);
						&.completed {
							@include opacity(1);
						}
					}
				}
				.CombinedResult-missionIcon {
					position: absolute;
					background-size: contain;
					width: 2em;
					height: 2em;
					right: -0.25em;
					bottom: 1.25em;
					&.cross {
						background-image: url(../../../assets/images/icons/icon-cross.svg);
					}
					&.check {
						background-image: url(../../../assets/images/icons/icon-check2.svg);
					}
				}
				.CombinedResult-completedMissionName {
					@include opacity(0.3);
					&.completed {
						@include opacity(1);
					}
					span {
						font-size: 0.9em;
						font-weight: 700;
						color: $textDark;
					}
				}
			}
		}
	}

	.CombinedResult-combinedResultWrapper {
		position: relative;

		padding: 0.5em;
		padding-bottom: 0.25em;
		padding-top: 0.2em;
		margin-bottom: 0.5em;
	
		background-color: $beigeLight;
		border-radius: 0.5em;

		.CombinedResult-header {
			@include flex('space-between');
			.CombinedResult-infoIcon {
				margin-top: 0.2em;
				width: 1.5em;
				height: 1.5em;
				background-image: url(../../../assets/images/icons/icon-info.svg);
				background-size: 1.2em 1.2em;
			}
		}

		.CombinedResult-combinedResult {
			@include flex('space-between', 'center', 'row');

			.CombinedResult-resultValue {
				@include flex('center', 'center', 'column');
				border-radius: 100%;
				width: 6.67em;
				height: 6.67em;
				margin-left: 1.25em;
				margin-top: -0.25em;
				
				&.color-1 {
					span {
						color: $red;
					}
				}

				&.color-2 {
					span {
						color: $orange2;
					}
				}

				&.color-3 {
					span {
						color: $orange;
					}
				}

				&.color-4 {
					span {
						color: $green2Light;
					}
				}

				&.color-5 {
					span {
						color: $green2;
					}
				}
				line-height: 4em;
				span {
					font-size: 4em;
					font-weight: 800;
					color: $white;
				}
				.CombinedResult-resultValueMax {
					line-height: 1em;
					span {
						font-size: 1.14em;
						font-weight: 700;
						color: $textDark;
					}
				}
			}

			.CombinedResult-resultSmiley {
				width: 4.7em;
				height: 4.7em;
				margin-right: 2.3em;
				background-size: contain;

				&.smiley-1 {
					background-image: url(../../../assets/images/icons/icon-smiley-saddest.svg);
				}
				&.smiley-2 {
					background-image: url(../../../assets/images/icons/icon-smiley-sad.svg);
				}
				&.smiley-3 {
					background-image: url(../../../assets/images/icons/icon-smiley.svg);
				}
				&.smiley-4 {
					background-image: url(../../../assets/images/icons/icon-smiley-glad.svg);
				}
				&.smiley-5 {
					background-image: url(../../../assets/images/icons/icon-smiley-gladdest.svg);
				}
			}
		}
	}

	.CombinedResult-pointResultPopup {
		position: absolute;
		bottom: 5em;
		left: -16.75em;
		@include flex('','','column');
		.CombinedResult-pointResultPopupArrow {
			position: absolute;
			background-image: url(../../../assets/images/dialogueArrowRight.svg);
			background-size: contain;
			width: 1.8em;
			height: 1.8em;
			top: 0.5em;
			right: -1.15em;
			z-index: 1;
		}
		.CombinedResult-pointResultPopupContent {
			@include no-select();
			border-radius: 0.5em;
			border-bottom-style: solid;
			border-bottom-color: $beigeDark;
			background-color: $beigeLight;
			width: 15.25em;
			padding: 0.5em;
			z-index: 2;
			line-height: 0.8em;

			span {
				color: $textDark;
				font-size: 0.7em;
				font-weight: 600;
			}
		}
	}
}