@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ChoiceInfo {
	@include fade();
	position: relative;
	border-radius: 0.5em;

	width: 17.62em;
	@include box-shadow(0, 0.14em, 0.2em, 0em, rgba(black, 0.2));
	
	background-image: linear-gradient(
		to bottom,
		$greenLight,
		$green
	);

	border-bottom: solid;
	border-bottom-color: $greenDark;
	border-bottom-width: 0.33em;
	padding: 0.5em;
	padding-top: 0.1em;
	padding-bottom: 0.5em;

	.ChoiceInfo-title {
		@include no-select();
		padding-left: 0.5em;

		span {
			font-size: 1.1em;
			font-weight: 800;
			color: $white;
		}
	}

	.ChoiceInfo-choice {
		position: relative;
		padding: 0.35em;
		margin-top: 0.5em;
		border-radius: 0.5em;
		background-color: $beigeLight;
	
		.ChoiceInfo-choiceTitle {
			padding-left: 0.75em;
			@include no-select();
			
			span {
				font-size: 1.1em;
				font-weight: 700;
				color: $textDark;
				text-transform: capitalize;
			}
		}

		.ChoiceInfo-choiceNameWrapper {
			@include flex();
			position: relative;
			flex-wrap: wrap;
			width: 100%;
		}

		.ChoiceInfo-choiceName {
			background-color: rgba($greenLight, 0.3);
			border-radius: 0.4em;
			padding-left: 0.76em;
			padding-right: 0.76em;
			padding-bottom: 0.25em;
			@include no-select();

			&.wrapped {
				@include flex('','','row');
				width: fit-content;
				margin-right: 0.2em;
				margin-bottom: 0.2em;
			}

			span {
				font-size: 1.2em;
				font-weight: 800;
				color: $textDark;
				text-transform: capitalize;
			}
		}
	}
}