/* COOKIES  */
@-webkit-keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}


/* BADGES */
@mixin fade($seconds: 0.25s) {
	-webkit-animation: fade $seconds ease-out forwards;
	animation: fade $seconds ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}
@keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}


/* MATERIALS ICON */
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}
@keyframes shake {
	0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}

/* FOOD ANIMATION */
@mixin animateFood($seconds: 1s) {
	-webkit-animation: animateFood $seconds ease-out forwards;
	animation: animateFood $seconds ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes animateFood {
	0% {
    @include opacity(0);
    @include scale(0);
  }
  30% {
    @include opacity(1);
    @include scale(1);
  }
  45% {
    @include scale(1);
  }
  60% {
    //@include scale(1.1);
    @include translate(0, 1em);
  }
  75% {
    //@include scale(1);
    @include translate(0, 0);
  }
  80% {
    //@include scale(1.1);
    @include translate(0, 1em);
  }
	100% {
    @include translate(0, 0);
    @include scale(1);
  }
}
@keyframes animateFood {
	0% {
    @include opacity(0);
    @include scale(0);
  }
  30% {
    @include opacity(1);
    @include scale(1);
  }
  45% {
    @include scale(1);
    filter: drop-shadow( 0 0 0.2em $white) drop-shadow( 0 0 0.5em $white) drop-shadow( 0 0 1em $white);
  }
  60% {
    filter: drop-shadow( 0 0 0.5em $white) drop-shadow( 0 0 0.75em rgba($white, 0.75)) drop-shadow( 0 0 1em rgba($white, 0.5));
  }
  75% {
    filter: drop-shadow( 0 0 0.2em $white) drop-shadow( 0 0 0.5em $white) drop-shadow( 0 0 1em $white);
  }
  95% {
    filter: drop-shadow( 0 0 0.5em $white) drop-shadow( 0 0 0.75em rgba($white, 0.75)) drop-shadow( 0 0 1em rgba($white, 0.5));
  }
	100% {
    @include translate(0, 0);
    @include scale(1);
  }
}

/* PARTICLES ANIMATION */
@mixin wriggle($seconds: 1s) {
	-webkit-animation: wriggle $seconds ease-in-out infinite;
	animation: wriggle $seconds ease-in-out infinite;
  transform-origin: center center;
}

@-webkit-keyframes wriggle {
	0% {
    @include translate(1em);
  }
  50% {
    @include translate(-1em);
  }
	100% {
    @include translate(1em);
  }
}
@keyframes wriggle {
	0% {
    @include translate(1em);
  }
  50% {
    @include translate(-1em);
  }
	100% {
    @include translate(1em);
  }
}

@mixin rise($seconds: 1s) {
	-webkit-animation: rise $seconds linear infinite;
	animation: rise $seconds linear infinite;
  transform-origin: center center;
}

@-webkit-keyframes rise {
	0% {
    @include opacity(1);
    @include translate(0, 0em);
  }
  50% {
    @include translate(0, -3em);
  }
	100% {
    @include translate(0, -6em);
  }
}
@keyframes rise {
	0% {
    @include opacity(1);
    @include translate(0, 0em);
  }
  50% {
    @include translate(0, -3em);
  }
	100% {
    @include translate(0, -6em);
  }
}

@mixin fadeOut($seconds: 1s) {
	-webkit-animation: fadeOut $seconds linear infinite;
	animation: fadeOut $seconds linear infinite;
  transform-origin: center center;
}

@-webkit-keyframes fadeOut {
	0% {
    @include opacity(0);
  }
  10% {
    @include opacity(0.5);
  }
	100% {
    @include opacity(0);
  }
}
@keyframes fadeOut {
	0% {
    @include opacity(0);
  }
  10% {
    @include opacity(0.5);
  }
	100% {
    @include opacity(0);
  }
}

@mixin glow($seconds: 1s) {
	-webkit-animation: glow $seconds linear infinite;
	animation: glow $seconds linear infinite;
  transform-origin: center center;
}

@-webkit-keyframes glow {
	0% {
    fill: $redGlowDark;
  }
  50% {
    fill: $redGlow;
  }
	100% {
    fill: $redGlowDark;
  }
}
@keyframes glow {
	0% {
    fill: $redGlowDark;
  }
  50% {
    fill: $redGlow;
  }
	100% {
    fill: $redGlowDark;
  }
}

@mixin lidShake($seconds: 0.5s) {
	-webkit-animation: lidShake $seconds linear infinite;
	animation: lidShake $seconds linear infinite;
  transform-origin: center center;
}

@-webkit-keyframes lidShake {
	0% {
    @include rotate(0.25deg);
  }
  50% {
    @include rotate(-0.25deg);
  }
	100% {
    @include rotate(0.25deg);
  }
}
@keyframes lidShake {
	0% {
    @include rotate(0.25deg);
  }
  50% {
    @include rotate(-0.25deg);
  }
	100% {
    @include rotate(0.25deg);
  }
}

@mixin lidJump($seconds: 0.25s) {
	-webkit-animation: lidJump $seconds linear infinite;
	animation: lidJump $seconds linear infinite;
  transform-origin: center center;
}

@-webkit-keyframes lidJump {
	0% {
    @include translate(0, 0.1em);
  }
  50% {
    @include translate(0, -0.1em);
  }
	100% {
    @include translate(0, 0.1em);
  }
}
@keyframes lidJump {
	0% {
    @include translate(0, 0.1em);
  }
  50% {
    @include translate(0, -0.1em);
  }
	100% {
    @include translate(0, 0.1em);
  }
}

// INFO POPUP ANIMATIONS
@mixin dissapearToPoint($seconds: 0.25s) {
	-webkit-animation: dissapearToPoint $seconds linear forwards;
	animation: dissapearToPoint $seconds linear forwards;
  transform-origin: top right;
}

@-webkit-keyframes dissapearToPoint {
	0% {
    @include scale(1);
		top: calc(50% - 38.1em / 2);
		left: calc(50% - 47.6em / 2);
  }
	100% {
    @include scale(0);
		top: calc(1.5em);
		left: calc(100% - 47.6em - 1.5em);
  }
}
@keyframes dissapearToPoint {
	0% {
    @include scale(1);
		top: calc(50% - 38.1em / 2);
		left: calc(50% - 47.6em / 2);
  }
	100% {
    @include scale(0);
		top: calc(1.5em);
		left: calc(100% - 47.6em - 1.5em);
  }
}


@mixin openFromPoint($seconds: 0.25s) {
	-webkit-animation: openFromPoint $seconds linear forwards;
	animation: openFromPoint $seconds linear forwards;
  transform-origin: top right;
}

@-webkit-keyframes openFromPoint {
	0% {
    @include scale(0);
		top: calc(1.5em);
		left: calc(100% - 47.6em - 1.5em);
  }
	100% {
    @include scale(1);
		top: calc(50% - 38.1em / 2);
		left: calc(50% - 47.6em / 2);
  }
}
@keyframes openFromPoint {
	0% {
    @include scale(0);
		top: calc(1.5em);
		left: calc(100% - 47.6em - 1.5em);
  }
	100% {
    @include scale(1);
		top: calc(50% - 38.1em / 2);
		left: calc(50% - 47.6em / 2);
  }
}