@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.RecipeComponent {
	position: relative;
	width: 17em;

	.RecipeComponent-wrapper {
		position: relative;
		background-color: $beigeLight;
		border-bottom-style: solid;
		border-bottom-color: $beigeDark;
		border-bottom-width: 0.24em;
		border-radius: 0.5em;
		padding: 0.35em;

		@include transition('transform', 0.15);

		&:hover:not(.isList) {
			cursor: pointer;
			@include scale(1.2);
		}

		&.selected {
			@include box-shadow(0, 0, 0, 0.4em, $greenDark);
		}

		.RecipeComponent-checkmark {
			position: absolute;
			background-image: url(../../../assets/images/icons/icon-check.svg);
			background-size: contain;
			width: 1.7em;
			height: 1.7em;
			top: 0.22em;
			right: 0.7em;
		}

		.RecipeComponent-title {
			padding-top: 0.15em;
			padding-left: 0.8em;
			height: 1.8em;
			line-height: 1em;
			span {
				@include no-select();
				font-size: 1em;
				font-weight: 700;
				text-transform: capitalize;
				color: $textDark;
			}
		}

		.RecipeComponent-choice {
			@include flex('', 'center');
			background-color: rgba($green, 0.3);
			min-height: 1.6em;
			width: 100%;
			padding: 0.2em;
			padding-bottom: 0.4em;
			padding-left: 0.9em;
			padding-right: 0.5em;
			border-radius: 0.5em;
			line-height: 1em;

			@include no-select();

			span {
				@include no-select();
				font-size: 1em;
				font-weight: 700;
				color: $textDark;
			}
		}
	}

	&.isList {
		border-bottom: none;
		margin-top: 0.5em;
		width: auto;
	}

	&.position-0 {
		position: absolute;
		left: calc(50% - 44em);
		top: 28em;
	}
	&.position-1 {
		position: absolute;
		left: calc(50% - 28.5em);
		top: 20.5em;
	}
	&.position-2 {
		position: absolute;
		left: calc(50% - 8.5em);
		top: 16em;
	}
	&.position-3 {
		position: absolute;
		left: calc(50% + 11.5em);
		top: 20.5em;
	}
	&.position-4 {
		position: absolute;
		left: calc(50% + 26.5em);
		top: 28em;
	}

	.RecipeComponent-recipeLine {
		position: fixed;

		pointer-events: none;
		@include origin(0, 0);
		background-color: rgba($greenDark, 1);
		clip-path: polygon(0 0, 0 0.3em, calc(100% - 0.3em) 100%, 100% 100%, 100% calc(100% - 0.3em), 0.3em 0);

		&.lasagna {
			&.position-0 {
				right: calc(50% + 10em);
				left: calc(50% - 32em);
				top: 31em;
				bottom: 16em;
			}
			&.position-1 {
				right: calc(50% + 4.5em);
				left: calc(50% - 20.5em);
				top: 20.5em;
				bottom: 19em;
			}
			&.position-2 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				right: calc(50% - 1em);
				left: calc(50% - 3.5em);
				top: 18em;
				bottom: 22em;
			}
			&.position-3 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				left: calc(50% + 8em);
				right: calc(50% - 18em);
				top: 22.5em;
				bottom: 18em;
			}
		}

		&.curry {
			&.position-0 {
				right: calc(50% - 8em);
				left: calc(50% - 32em);
				top: 31em;
				bottom: 17.4em;
			}
			&.position-1 {
				right: calc(50% + 7em);
				left: calc(50% - 20.5em);
				top: 20.5em;
				bottom: 16.4em;
			}
			&.position-2 {
				right: calc(50%);
				left: calc(50% - 1.5em);
				top: 18em;
				bottom: 20em;
			}
			&.position-3 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				left: calc(50% + 5em);
				right: calc(50% - 20em);
				top: 22.5em;
				bottom: 17em;
			}
			&.position-4 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				left: calc(50% + 5em);
				right: calc(50% - 32em);
				top: 30em;
				bottom: 12em;
			}
		}

		&.meatBalls {
			&.position-0 {
				right: calc(50% - 8em);
				left: calc(50% - 32em);
				top: 31em;
				bottom: 17.4em;
			}
			&.position-1 {
				right: calc(50% + 7em);
				left: calc(50% - 20.5em);
				top: 20.5em;
				bottom: 16.4em;
			}
			&.position-2 {
				right: calc(50%);
				left: calc(50% - 1.5em);
				top: 18em;
				bottom: 20em;
			}
			&.position-3 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				left: calc(50% - 1em);
				right: calc(50% - 18em);
				top: 22.5em;
				bottom: 12em;
			}
			&.position-4 {
				clip-path: polygon(0 100%, 0 calc(100% - 0.3em), calc(100% - 0.3em) 0, 100% 0, 100% 0.3em, 0.3em 100%);
				left: calc(50% + 5em);
				right: calc(50% - 32em);
				top: 30em;
				bottom: 12em;
			}
		}
	}

	.RecipeComponent-infoIcon {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
		background-image: url(../../../assets/images/icons/icon-info.svg);
		background-size: contain;
		width: 1em;
		height: 1em;
	}

	.RecipeComponent-infoBox {
		position: absolute;
		left: 18em;
		top: -1em;
	}
}