@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Plate {
	position: relative;
	width: 33.5em;
	height: auto;

	&.small {
		width: 12.3em;
		height: 5.8em;
	}

	&.curry {
		// STEW
		#komponent_1-01,
		#komponent_1-02,
		#komponent_1-03,
		#komponent_1-04,
		#komponent_1-05,
		#komponent_1-06,
		// SIDES
		#komponent_2-01,
		#komponent_2-02,
		#komponent_2-03,
		#komponent_2-04,
		#komponent_2-05,
		#komponent_2-06,
		#komponent_2-07,
		#komponent_2-08,
		// GREENS
		#komponent_3-01,
		#komponent_3-02,
		#komponent_3-03,
		#komponent_3-04,
		#komponent_3-05,
		#komponent_3-06,
		#komponent_3-07,
		#komponent_3-08,
		#komponent_3-09,
		#komponent_3-10,
		// TOPPINGS
		#komponent_4-01,
		#komponent_4-02,
		#komponent_4-03,
		#komponent_4-04,
		#komponent_4-05,
		#komponent_4-06,
		// EXTRAS
		#komponent_5-01,
		#komponent_5-02,
		#komponent_5-03,
		#komponent_5-04,
		#komponent_5-05 {
			display: none;
		}

		// STEW
		&.chickenCurry {
			#komponent_1-01 {
				@include animateFood();
				display: block;
			}
		}
		&.curryPeaCauliflower {
			#komponent_1-02 {
				@include animateFood();
				display: block;
			}
		}
		&.curryChickpeaCauliflowerPig {
			#komponent_1-03 {
				@include animateFood();
				display: block;
			}
		}
		&.daalGreenLenses {
			#komponent_1-04 {
				@include animateFood();
				display: block;
			}
		}
		&.cabbageBaconApple {
			#komponent_1-05 {
				@include animateFood();
				display: block;
			}
		}
		&.fishCurryCoconutSauce {
			#komponent_1-06 {
				@include animateFood();
				display: block;
			}
		}
		// SIDES
		&.riceParboiled {
			#komponent_2-01 {
				@include animateFood();
				display: block;
			}
		}
		&.riceBrown {
			#komponent_2-02 {
				@include animateFood();
				display: block;
			}
		}
		&.butterRiceApricot {
			#komponent_2-03 {
				@include animateFood();
				display: block;
			}
		}
		&.potatoboats {
			#komponent_2-04 {
				@include animateFood();
				display: block;
			}
		}
		&.pastaStrips {
			#komponent_2-05 {
				@include animateFood();
				display: block;
			}
		}
		&.beanpastaScrews {
			#komponent_2-06 {
				@include animateFood();
				display: block;
			}
		}
		&.potatoBoiled {
			#komponent_2-07 {
				@include animateFood();
				display: block;
			}
		}
		&.eggnoodles {
			#komponent_2-08 {
				@include animateFood();
				display: block;
			}
		}
		// GREENS
		&.winterkaleApple {
			#komponent_3-01 {
				@include animateFood();
				display: block;
			}
		}
		&.romaneSteamed {
			#komponent_3-02 {
				@include animateFood();
				display: block;
			}
		}
		&.mintyoghurtAlmondsCucumberTomato {
			#komponent_3-03 {
				@include animateFood();
				display: block;
			}
		}
		&.haricotSteamed {
			#komponent_3-04 {
				@include animateFood();
				display: block;
			}
		}
		&.rawCarrotNutSalat {
			#komponent_3-05 {
				@include animateFood();
				display: block;
			}
		}
		&.butterBrusselSprouts {
			#komponent_3-06 {
				@include animateFood();
				display: block;
			}
		}
		&.cucumberSalat {
			#komponent_3-07 {
				@include animateFood();
				display: block;
			}
		}
		&.ovenbakedCarrots {
			#komponent_3-08 {
				@include animateFood();
				display: block;
			}
		}
		&.bananaSalat {
			#komponent_3-09 {
				@include animateFood();
				display: block;
			}
		}
		&.friedMushrooms {
			#komponent_3-10 {
				@include animateFood();
				display: block;
			}
		}
		// TOPPINGS
		&.coconutFlakes {
			#komponent_4-01 {
				@include animateFood();
				display: block;
			}
		}
		&.peanuts {
			#komponent_4-02 {
				@include animateFood();
				display: block;
			}
		}
		&.raisins {
			#komponent_4-03 {
				@include animateFood();
				display: block;
			}
		}
		&.honeyroastedSunflowerseeds {
			#komponent_4-04 {
				@include animateFood();
				display: block;
			}
		}
		&.dryMango {
			#komponent_4-05 {
				@include animateFood();
				display: block;
			}
		}
		&.freshBanana {
			#komponent_4-06 {
				@include animateFood();
				display: block;
			}
		}
		// EXTRAS
		&.mangoChutney {
			#komponent_5-01 {
				@include animateFood();
				display: block;
			}
		}
		&.pumpkinMarmalade {
			#komponent_5-02 {
				@include animateFood();
				display: block;
			}
		}
		&.pickledLingonberries {
			#komponent_5-03 {
				@include animateFood();
				display: block;
			}
		}
		&.cottageCheese {
			#komponent_5-04 {
				@include animateFood();
				display: block;
			}
		}
		&.pickledAsier {
			#komponent_5-05 {
				@include animateFood();
				display: block;
			}
		}
	}

	&.lasagna {
		// STEW
		#komponent_1-01a,
		#komponent_1-01b,
		#komponent_1-01c,
		#komponent_1-02a,
		#komponent_1-02b,
		#komponent_1-02c,
		#komponent_1-03a,
		#komponent_1-03b,
		#komponent_1-03c,
		#komponent_1-04a,
		#komponent_1-04b,
		#komponent_1-04c,
		#komponent_1-05a,
		#komponent_1-05b,
		#komponent_1-05c,
		#komponent_1-06a,
		#komponent_1-06b,
		#komponent_1-06c,
		// PLATES
		#komponent_2-01a,
		#komponent_2-01b,
		#komponent_2-01c,
		#komponent_2-01d,

		#komponent_2-02a,
		#komponent_2-02b,
		#komponent_2-02c,
		#komponent_2-02d,

		#komponent_2-03a,
		#komponent_2-03b,
		#komponent_2-03c,
		#komponent_2-03d,

		#komponent_2-04a,
		#komponent_2-04b,
		#komponent_2-04c,
		#komponent_2-04d,

		#komponent_2-05a,
		#komponent_2-05b,
		#komponent_2-05c,
		#komponent_2-05d,

		#komponent_2-06a,
		#komponent_2-06b,
		#komponent_2-06c,
		#komponent_2-06d,

		#komponent_2-07a,
		#komponent_2-07b,
		#komponent_2-07c,
		#komponent_2-07d,
		// TOPPINGS
		#komponent_3-01,
		#komponent_3-02,
		#komponent_3-03,
		#komponent_3-04,
		// SIDES
		#komponent_4-01,
		#komponent_4-02,
		#komponent_4-03,
		#komponent_4-04,
		#komponent_4-05,
		#komponent_4-06,
		#komponent_4-07,
		#komponent_4-08,
		#komponent_4-09,
		#komponent_4-10,
		#komponent_4-11  {
			display: none;
		}

		// STEW
		&.bologneseBechamelCow {
			#komponent_1-01a,
			#komponent_1-01b,
			#komponent_1-01c {
				@include animateFood();
				display: block;
			}
		}
		&.bologneseBechamelSquashCow {
			#komponent_1-02a,
			#komponent_1-02b,
			#komponent_1-02c {
				@include animateFood();
				display: block;
			}
		}
		&.bolognesePeaProtein {
			#komponent_1-03a,
			#komponent_1-03b,
			#komponent_1-03c {
				@include animateFood();
				display: block;
			}
		}
		&.tomatosauceVeggieBechamel {
			#komponent_1-04a,
			#komponent_1-04b,
			#komponent_1-04c {
				@include animateFood();
				display: block;
			}
		}
		&.tomatosauceVeganCheese {
			#komponent_1-05a,
			#komponent_1-05b,
			#komponent_1-05c {
				@include animateFood();
				display: block;
			}
		}
		&.codSpinachTomatosauce {
			#komponent_1-06a,
			#komponent_1-06b,
			#komponent_1-06c {
				@include animateFood();
				display: block;
			}
		}

		// PLATES
		&.lasagnaPlates {
			#komponent_2-01a,
			#komponent_2-01b,
			#komponent_2-01c,
			#komponent_2-01d {
				@include animateFood();
				display: block;
			}
		}
		&.potatoSlices {
			#komponent_2-02a,
			#komponent_2-02b,
			#komponent_2-02c,
			#komponent_2-02d {
				@include animateFood();
				display: block;
			}
		}
		&.aubergineSlices {
			#komponent_2-03a,
			#komponent_2-03b,
			#komponent_2-03c,
			#komponent_2-03d {
				@include animateFood();
				display: block;
			}
		}
		&.lasagnaPlatesWholeGrain {
			#komponent_2-04a,
			#komponent_2-04b,
			#komponent_2-04c,
			#komponent_2-04d {
				@include animateFood();
				display: block;
			}
		}
		&.cauliflowerSlices {
			#komponent_2-05a,
			#komponent_2-05b,
			#komponent_2-05c,
			#komponent_2-05d {
				@include animateFood();
				display: block;
			}
		}
		&.beanpastaScrews {
			#komponent_2-06a,
			#komponent_2-06b,
			#komponent_2-06c,
			#komponent_2-06d {
				@include animateFood();
				display: block;
			}
		}
		&.roastedMushrooms {
			#komponent_2-07a,
			#komponent_2-07b,
			#komponent_2-07c,
			#komponent_2-07d {
				@include animateFood();
				display: block;
			}
		}

		// TOPPINGS
		&.mozarella {
			#komponent_3-01 {
				@include animateFood();
				display: block;
			}
		}
		&.cottageCheese {
			#komponent_3-02 {
				@include animateFood();
				display: block;
			}
		}
		&.parmesanCheeseGrated {
			#komponent_3-03 {
				@include animateFood();
				display: block;
			}
		}
		&.nutcrunchSage {
			#komponent_3-04 {
				@include animateFood();
				display: block;
			}
		}

		// SIDES
		&.spicyTomatoSalat {
			#komponent_4-01 {
				@include animateFood();
				display: block;
			}
		}
		&.winterkaleApple {
			#komponent_4-02 {
				@include animateFood();
				display: block;
			}
		}
		&.romaneSteamed {
			#komponent_4-03 {
				@include animateFood();
				display: block;
			}
		}
		&.haricotSteamed {
			#komponent_4-04 {
				@include animateFood();
				display: block;
			}
		}
		&.ovenbakedCarrots {
			#komponent_4-05 {
				@include animateFood();
				display: block;
			}
		}
		&.mintyoghurtAlmondsCucumberTomato {
			#komponent_4-06 {
				@include animateFood();
				display: block;
			}
		}
		&.rawCarrotNutSalat {
			#komponent_4-07 {
				@include animateFood();
				display: block;
			}
		}
		&.butterBrusselSprouts {
			#komponent_4-08 {
				@include animateFood();
				display: block;
			}
		}
		&.cucumberSalat {
			#komponent_4-09 {
				@include animateFood();
				display: block;
			}
		}
		&.bananaSalat {
			#komponent_4-10 {
				@include animateFood();
				display: block;
			}
		}
		&.friedMushrooms {
			#komponent_4-11 {
				@include animateFood();
				display: block;
			}
		}
	}

	&.meatBalls {
		// MEATBALLS
		#komponent_1-01,
		#komponent_1-02,
		#komponent_1-03,
		#komponent_1-04,
		#komponent_1-05,
		#komponent_1-06,
		#komponent_1-07,
		#komponent_1-08,
		// SIDES
		#komponent_2-01,
		#komponent_2-02,
		#komponent_2-03,
		#komponent_2-04,
		#komponent_2-05,
		#komponent_2-06,
		#komponent_2-07,
		// GREENS
		#komponent_3-01,
		#komponent_3-02,
		#komponent_3-03,
		#komponent_3-04,
		#komponent_3-05,
		#komponent_3-06,
		#komponent_3-07,
		#komponent_3-08,
		#komponent_3-09,
		#komponent_3-10,
		// SAUCE
		#komponent_4-01,
		#komponent_4-02,
		#komponent_4-03,
		#komponent_4-04,
		#komponent_4-05,
		// EXTRAS
		#komponent_5-01,
		#komponent_5-02,
		#komponent_5-03,
		#komponent_5-04,
		#komponent_5-05 {
			display: none;
		}

		// MEATBALLS
		&.choppedPig {
			#komponent_1-01 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedCow {
			#komponent_1-02 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedChicken {
			#komponent_1-03 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedPigBeans {
			#komponent_1-04 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedLamb {
			#komponent_1-05 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedPigPeas {
			#komponent_1-06 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedQuinoaChickpea {
			#komponent_1-07 {
				@include animateFood();
				display: block;
			}
		}
		&.choppedFish {
			#komponent_1-08 {
				@include animateFood();
				display: block;
			}
		}
		// SIDES
		&.potatoBoiled {
			#komponent_2-01 {
				@include animateFood();
				display: block;
			}
		}
		&.pastaStrips {
			#komponent_2-02 {
				@include animateFood();
				display: block;
			}
		}
		&.lensesKale {
			#komponent_2-03 {
				@include animateFood();
				display: block;
			}
		}
		&.potatoboats {
			#komponent_2-04 {
				@include animateFood();
				display: block;
			}
		}
		&.beanpastaScrews {
			#komponent_2-05 {
				@include animateFood();
				display: block;
			}
		}
		&.riceParboiled {
			#komponent_2-06 {
				@include animateFood();
				display: block;
			}
		}
		&.eggnoodles {
			#komponent_2-07 {
				@include animateFood();
				display: block;
			}
		}
		// GREENS
		&.winterkaleApple {
			#komponent_3-01 {
				@include animateFood();
				display: block;
			}
		}
		&.romaneSteamed {
			#komponent_3-02 {
				@include animateFood();
				display: block;
			}
		}
		&.ovenbakedCarrots {
			#komponent_3-03 {
				@include animateFood();
				display: block;
			}
		}
		&.pickledRedCabbage {
			#komponent_3-04 {
				@include animateFood();
				display: block;
			}
		}
		&.haricotSteamed {
			#komponent_3-05 {
				@include animateFood();
				display: block;
			}
		}
		&.rawCarrotNutSalat {
			#komponent_3-06 {
				@include animateFood();
				display: block;
			}
		}
		&.butterBrusselSprouts {
			#komponent_3-07 {
				@include animateFood();
				display: block;
			}
		}
		&.cucumberSalat {
			#komponent_3-08 {
				@include animateFood();
				display: block;
			}
		}
		&.bananaSalat {
			#komponent_3-09 {
				@include animateFood();
				display: block;
			}
		}
		&.friedMushrooms {
			#komponent_3-10 {
				@include animateFood();
				display: block;
			}
		}
		// SAUCE
		&.skysauce {
			#komponent_4-01 {
				@include animateFood();
				display: block;
			}
		}
		&.stewedCabbageSpinach {
			#komponent_4-02 {
				@include animateFood();
				display: block;
			}
		}
		&.tomatoSauceParmasan {
			#komponent_4-03 {
				@include animateFood();
				display: block;
			}
		}
		&.remouladeSauce {
			#komponent_4-04 {
				@include animateFood();
				display: block;
			}
		}
		&.sennepCreme {
			#komponent_4-05 {
				@include animateFood();
				display: block;
			}
		}
		// EXTRAS
		&.mangoChutney {
			#komponent_5-01 {
				@include animateFood();
				display: block;
			}
		}
		&.pumpkinMarmalade {
			#komponent_5-02 {
				@include animateFood();
				display: block;
			}
		}
		&.pickledLingonberries {
			#komponent_5-03 {
				@include animateFood();
				display: block;
			}
		}
		&.cottageCheese {
			#komponent_5-04 {
				@include animateFood();
				display: block;
			}
		}
		&.pickledAsier {
			#komponent_5-05 {
				@include animateFood();
				display: block;
			}
		}
	}
}