$white: #ffffff;
$black: #000000;
$textDark: #041F4F;

$blueGreyLighter: #8DBAD4;
$blueGreyLight: #85AECA;
$blueGrey: #436980;
$blueLightest: #4D5E82;
$blueLighter: #3D4C6A;
$blueLight: #05435D;
$blue: #043A5D;
$blue2: #083B5C;
$blueDarker: #1B2839;
$blueDarkest: #0F1A26;
$popupBlue: #03183C;

$greenLight: #75BF4A;
$green: #5C9C37;
$greenDark: #487D28;
$greenDarker: #38661b;

$beigeLight: #FFF6ED;
$beigeDark: #DBCDC2;

$green2Light: #ABB32C;
$green2: #21B536;
$orange: #EAC500;
$orange2: #EF8834;
$yellow: #fff832;
$red: #FC4B46;
$redDark: #bf2b26;
$redDarker: #971e1a;
$redDarkest: #7d1a17;

$redGlow: rgb(137, 24, 24);
$redGlowDark: rgb(89, 41, 41);
