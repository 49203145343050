@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ChooseMissions {
	@include flex('', 'center', 'column');
	width: 100%;
	padding-bottom: 2.4em;
	
	.ChooseMissions-title {
		@include no-select();
		span {
			font-size: 2.2em;
			font-weight: 800;
			text-transform: uppercase;
			color: $white;
		}
	}
	.ChooseMissions-subTitle {
		margin-bottom: 2em;
		@include no-select();
		width: 25em;
		text-align: center;

		font-weight: 700;
		color: $white;
	}
	.ChooseMissions-recipes {
		@include flex('center', 'center');

		.ChooseMissions-recipeButton {
			margin: 1em;
		}
	}
	.ChooseMissions-navigation {
		position: absolute;
		width: 100%;
		@include flex('space-between');
		padding-left: 2.4em;
		padding-right: 2.4em;
		bottom: 2.43em;
	}
}
