@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Background {
	position: absolute;
	pointer-events: none;
	overflow: hidden;
	height: 100%;
	width: 100%;
	background-color: $blue2;
	z-index: 0;
	
	background-image: 
		linear-gradient(
			to top,
			$blueDarkest,
			$blueDarker 20%,
			$blueLighter 60%,
			$blueLightest 70%,
			$blueGreyLight 90%,
			$blueGreyLighter,
		);
	background-size: 100% 100%;
	background-position: bottom center;

	.Background-overlay {
		top: 0;
		z-index: 3;
		position: absolute;
		pointer-events: none;
		background-image: url('../../assets/images/background/overlay.svg');
		background-size: cover;
		width: 100%;
		height: 100%;
	}

	.Background-kitchen {
		position: absolute;
		top: -5em;
		left: -14em;
		z-index: -1;
		width: 117%;

		.Background-steam {
			position: absolute;
			display: none;

			&.lightsOn {
				display: block;
			}

			&.pos-1 {
				top: calc(50% - 0.5em);
				left: calc(50% + 9.5em);
			}
			&.pos-2 {
				top: calc(50% + 1.75em);
				left: calc(50% + 1.25em);
			}
		}

		.Background-kitchenSvg {
			position: relative;
			width: 100%;
			height: 100%;

			#kitchen_light {
				display: none;
			}

			&.lightsOn {
				#kitchen_light {
					display: block
				}
				#Ellipse_493,
				#Ellipse_494 {
					@include glow(2s);
				}
				#lidWrapper {
					@include lidShake();
				}
				#lid {
					@include lidJump();
				}
			}
		}
	}

	.Background-table {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: url(../../assets/images/background/table.svg);
		background-size: 108.44em 29.5em;
		background-position: bottom -5.4em center;

		.Background-plate {
			pointer-events: none;
			position: absolute;
			bottom: 5.4em;
			left: calc(50% - 32.75em / 2);
		}
	}
}