@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';


.IngredientInfobox {
	position: relative;
	width: 15em;
	border-radius: 0.5em;
	border-bottom-style: solid;
	border-bottom-color: $beigeDark;
	
	&.isList {
		&::after {
			content: '';
			position: absolute;
			background-image: url(../../../assets/images/dialogueArrowLeft.svg);
			background-size: contain;
			width: 1.8em;
			height: 1.8em;
			top: 1.25em;
			left: -1.2em;
		}
	}
	&:not(.isList) {
		&::after {
			content: '';
			position: absolute;
			background-image: url(../../../assets/images/dialogueArrowDown.svg);
			background-size: contain;
			width: 1.8em;
			height: 1.8em;
			bottom: -1.5em;
			left: 0.25em;
			z-index: 5;
		}
	}
	.IngredientInfobox-wrapper {
		position: relative;
		padding: 0.5em;
		border-radius: 0.4em;
		background-color: $beigeLight;
		z-index: 2;

		.IngredientInfobox-infoBoxItem {
			width: 100%;
			border-bottom-style: solid;
			border-bottom-width: 0.05em;
			border-bottom-color: $beigeDark;
			@include flex('space-between', 'center');

			span {
				font-weight: 700;
				font-size: 0.85em;
				color: $textDark;
			}

			.IngredientInfobox-infoBoxValue {
				width: 0.9em;
				height: 0.9em;
				border-radius: 0.5em;
				&.co2-5,
				&.price-1,
				&.protein-1X,
				&.protein-1Y,
				&.protein-1 {
					background-color: $red;
				}

				&.co2-4 {
					background-color: $orange2;
				}

				&.co2-3,
				&.price-2,
				&.protein-2 {
					background-color: $orange;
				}

				&.co2-2 {
					background-color: $green2Light
				}

				&.co2-1,
				&.price-3,
				&.protein-3 {
					background-color: $green2;
				}
			}
		}
		.IngredientInfobox-infoBoxKeywords {
			width: 100%;
			@include flex();
			flex-wrap: wrap;
			margin-top: 0.25em;
			.IngredientInfobox-infoBoxKeyword {
				padding-bottom: 0.2em;
				padding-right: 0.5em;
				padding-left: 0.5em;
				background-color: rgba($greenLight, 0.3);
				border-radius: 0.24em;
				margin: 0.1em;

				span {
					font-weight: 700;
					color: $textDark;
					font-size: 0.85em;
					text-transform: capitalize;
				}
			}
		}
	}
}