@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.IngredientChoice {
	cursor: pointer;
	pointer-events: auto;
	@include flex('space-between','','column');
	@include box-shadow-inset(0, -0.33em, 0, 0, $beigeDark);
	transform-origin: bottom center;
	min-width: 15.5em;
	max-width: 15.5em;
	margin-bottom: 1em;
	border-radius: 0.5em;
	background-color: $beigeLight;

	@include transition('transform', 0.15);

	&:hover {
		@include scale(1.1);
		@include box-shadow(0, 0, 0, 0.4em, $greenDark);
		z-index: 2;
	}

	.IngredientChoice-ingredientContent {
		position: relative;
		height: 6.1em;
		
		.IngredientChoice-infoIcon {
			pointer-events: auto;
			position: absolute;
			top: 0.3em;
			left: 0.3em;
			width: 1.4em;
			height: 1.4em;

			background-size: 1.2em 1.2em;
			background-image: url(../../../assets/images/icons/icon-info.svg);
			z-index: 2;
		}

		.IngredientChoice-selectedMark {
			position: absolute;
			width: 1.8em;
			height: 1.8em;
			top: 0.4em;
			right: 0.4em;
			background-image: url(../../../assets/images/icons/icon-check.svg);
			background-size: contain;
		}

		.IngredientChoice-ingredientImage {
			position: relative;
			height: 100%;
			width: 100%;
			background-size: auto 5.5em;

			&.nothing {
				background-image: url(../../../assets/images/ingredients/nothing.svg);
				background-size: auto 4em;
			}
			
			// STEW
			&.chickenCurry {
				background-image: url(../../../assets/images/ingredients/stew/chickenCurry.svg);
			}
			&.curryPeaCauliflower {
				background-image: url(../../../assets/images/ingredients/stew/curryPeaCauliflower.svg);
			}
			&.curryChickpeaCauliflowerPig {
				background-image: url(../../../assets/images/ingredients/stew/curryChickpeaCauliflowerPig.svg);
			}
			&.daalGreenLenses {
				background-image: url(../../../assets/images/ingredients/stew/daalGreenLenses.svg);
			}
			&.cabbageBaconApple {
				background-image: url(../../../assets/images/ingredients/stew/cabbageBaconApple.svg);
			}
			&.fishCurryCoconutSauce {
				background-image: url(../../../assets/images/ingredients/stew/fishCurryCoconutSauce.svg);
			}
			// SIDES
			&.riceBrown {
				background-image: url(../../../assets/images/ingredients/sides/riceBrown.svg);
			}
			&.butterRiceApricot {
				background-image: url(../../../assets/images/ingredients/sides/butterRiceApricot.svg);
			}
			// TOPPINGS
			&.coconutFlakes {
				background-image: url(../../../assets/images/ingredients/toppings/coconutFlakes.svg);
				background-size: auto 3em;
			}
			&.peanuts {
				background-image: url(../../../assets/images/ingredients/toppings/peanuts.svg);
				background-size: auto 3.5em;
			}
			&.raisins {
				background-image: url(../../../assets/images/ingredients/toppings/raisins.svg);
				background-size: auto 3.5em;
			}
			&.honeyroastedSunflowerseeds {
				background-image: url(../../../assets/images/ingredients/toppings/honeyroastedSunflowerseeds.svg);
				background-size: auto 2.75em;
			}
			&.dryMango {
				background-image: url(../../../assets/images/ingredients/toppings/dryMango.svg);
				background-size: auto 3.5em;
			}
			&.freshBanana {
				background-image: url(../../../assets/images/ingredients/toppings/freshBanana.svg);
				background-size: auto 4.5em;
			}

			// STEW
			&.bologneseBechamelCow {
				background-image: url(../../../assets/images/ingredients/stew/bologneseBechamelCow.svg);
				background-size: auto 5em;
			}
			&.bologneseBechamelSquashCow {
				background-image: url(../../../assets/images/ingredients/stew/bologneseBechamelSquashCow.svg);
				background-size: auto 5em;
			}
			&.bolognesePeaProtein {
				background-image: url(../../../assets/images/ingredients/stew/bolognesePeaProtein.svg);
				background-size: auto 5em;
			}
			&.tomatosauceVeggieBechamel {
				background-image: url(../../../assets/images/ingredients/stew/tomatosauceVeggieBechamel.svg);
				background-size: auto 5em;
			}
			&.tomatosauceVeganCheese {
				background-image: url(../../../assets/images/ingredients/stew/tomatosauceVeganCheese.svg);
				background-size: auto 5em;
			}
			&.codSpinachTomatosauce {
				background-image: url(../../../assets/images/ingredients/stew/codSpinachTomatosauce.svg);
				background-size: auto 5em;
			}
			
			// PLATES
			&.lasagnaPlates {
				background-image: url(../../../assets/images/ingredients/plates/lasagnaPlates.svg);
			}
			&.potatoSlices {
				background-image: url(../../../assets/images/ingredients/plates/potatoSlices.svg);
				background-size: auto 5em;
			}
			&.aubergineSlices {
				background-image: url(../../../assets/images/ingredients/plates/aubergineSlices.svg);
			}
			&.lasagnaPlatesWholeGrain {
				background-image: url(../../../assets/images/ingredients/plates/lasagnaPlatesWholeGrain.svg);
			}
			&.cauliflowerSlices {
				background-image: url(../../../assets/images/ingredients/plates/cauliflowerSlices.svg);
			}
			&.roastedMushrooms {
				background-image: url(../../../assets/images/ingredients/plates/roastedMushrooms.svg);
			}

			// TOPPINGS
			&.mozarella {
				background-image: url(../../../assets/images/ingredients/toppings/mozarella.svg);
			}
			&.parmesanCheeseGrated {
				background-image: url(../../../assets/images/ingredients/toppings/parmesanCheeseGrated.svg);
				background-size: auto 3.5em;
			}
			&.nutcrunchSage {
				background-image: url(../../../assets/images/ingredients/toppings/nutcrunchSage.svg);
			}

			// SIDES
			&.spicyTomatoSalat {
				background-image: url(../../../assets/images/ingredients/sides/spicyTomatoSalat.svg);
				background-size: auto 4em;
			}
			&.mintyoghurtAlmondsCucumberTomato {
				background-image: url(../../../assets/images/ingredients/sides/mintyoghurtAlmondsCucumberTomato.svg);
				background-size: auto 4.5em;
			}
			
			// MEATBALLS
			&.choppedPig {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedPig.svg);
				background-size: auto 4.25em;
			}
			&.choppedCow {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedCow.svg);
				background-size: auto 4.25em;
			}
			&.choppedChicken {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedChicken.svg);
				background-size: auto 4.25em;
			}
			&.choppedPigBeans {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedPigBeans.svg);
				background-size: auto 4.25em;
			}
			&.choppedLamb {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedLamb.svg);
				background-size: auto 4.25em;
			}
			&.choppedPigPeas {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedPigPeas.svg);
				background-size: auto 4.25em;
			}
			&.choppedQuinoaChickpea {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedQuinoaChickpea.svg);
				background-size: auto 4.25em;
			}
			&.choppedFish {
				background-image: url(../../../assets/images/ingredients/meatballs/choppedFish.svg);
				background-size: auto 4.25em;
			}
			// SIDES
			&.potatoBoiled {
				background-image: url(../../../assets/images/ingredients/sides/potato-boiled.svg);
			}
			&.pastaStrips {
				background-image: url(../../../assets/images/ingredients/sides/pasta-strips.svg);
				background-size: auto 7em;
			}
			&.lensesKale {
				background-image: url(../../../assets/images/ingredients/sides/lenses-kale.svg);
			}
			&.potatoboats {
				background-image: url(../../../assets/images/ingredients/sides/potatoboats.svg);
			}
			&.beanpastaScrews {
				background-image: url(../../../assets/images/ingredients/sides/beanpasta-screws.svg);
			}
			&.riceParboiled {
				background-image: url(../../../assets/images/ingredients/sides/rice-parboiled.svg);
			}
			&.eggnoodles {
				background-image: url(../../../assets/images/ingredients/sides/eggnoodles.svg);
			}
			// GREENS
			&.winterkaleApple {
				background-image: url(../../../assets/images/ingredients/greens/winterkaleApple.svg);
				background-size: auto 5em;
			}
			&.romaneSteamed {
				background-image: url(../../../assets/images/ingredients/greens/romaneSteamed.svg);
				background-size: auto 5em;
			}
			&.ovenbakedCarrots {
				background-image: url(../../../assets/images/ingredients/greens/ovenbakedCarrots.svg);
				background-size: auto 5em;
			}
			&.pickledRedCabbage {
				background-image: url(../../../assets/images/ingredients/greens/pickledRedCabbage.svg);
				background-size: auto 5em;
			}
			&.haricotSteamed {
				background-image: url(../../../assets/images/ingredients/greens/haricotSteamed.svg);
				background-size: auto 5em;
			}
			&.rawCarrotNutSalat {
				background-image: url(../../../assets/images/ingredients/greens/rawCarrotNutSalat.svg);
				background-size: auto 5em;
			}
			&.butterBrusselSprouts {
				background-image: url(../../../assets/images/ingredients/greens/butterBrusselSprouts.svg);
				background-size: auto 5em;
			}
			&.cucumberSalat {
				background-image: url(../../../assets/images/ingredients/greens/cucumberSalat.svg);
				background-size: auto 4.5em;
			}
			&.bananaSalat {
				background-image: url(../../../assets/images/ingredients/greens/bananaSalat.svg);
				background-size: auto 5em;
			}
			&.friedMushrooms {
				background-image: url(../../../assets/images/ingredients/greens/friedMushrooms.svg);
				background-size: auto 5em;
			}
			// SAUCE
			&.skysauce {
				background-image: url(../../../assets/images/ingredients/sauce/skysauce.svg);
				background-size: auto 5em;
			}
			&.stewedCabbageSpinach {
				background-image: url(../../../assets/images/ingredients/sauce/stewedCabbageSpinach.svg);
				background-size: auto 5em;
			}
			&.tomatoSauceParmasan {
				background-image: url(../../../assets/images/ingredients/sauce/tomatoSauceParmasan.svg);
				background-size: auto 5em;
			}
			&.remouladeSauce {
				background-image: url(../../../assets/images/ingredients/sauce/remouladeSauce.svg);
				background-size: auto 5em;
			}
			&.sennepCreme {
				background-image: url(../../../assets/images/ingredients/sauce/sennepCreme.svg);
				background-size: auto 5em;
			}
			// EXTRA
			&.mangoChutney {
				background-image: url(../../../assets/images/ingredients/extras/mangoChutney.svg);
			}
			&.pumpkinMarmalade {
				background-image: url(../../../assets/images/ingredients/extras/pumpkinMarmalade.svg);
			}
			&.pickledLingonberries {
				background-image: url(../../../assets/images/ingredients/extras/pickledLingonberries.svg);
			}
			&.cottageCheese {
				background-image: url(../../../assets/images/ingredients/extras/cottageCheese.svg);
				background-size: auto 5em;
			}
			&.pickledAsier {
				background-image: url(../../../assets/images/ingredients/extras/pickledAsier.svg);
			}
		}
	}

	.IngredientChoice-ingredientName {
		padding: 0.5em;
		padding-top: 0em;
		height: 3.4em;
		background-color: rgba($green, 0.4);
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		text-align: center;
		@include flex('center','','column');
		span {
			line-height: 1em;
			font-weight: 700;
			color: $textDark;
		}
	}

	.IngredientChoice-infoBox {
		position: absolute;
		bottom: 10.5em;
	}
}