@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SavedDishes {
	position: absolute;
	height: 12em;
	width: 100%;
	bottom: 0.93em;
	padding: 0.75em;
	padding-top: 0;
	padding-bottom: 0;

	.SavedDishes-dishList {
		position: relative;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		@include hide-scrollbar();
		padding: 0.75em;
		padding-left: 0;
		padding-right: 0;
		
		@include flex('', 'flex-end');

		
		.SavedDishes-savedDishWrapper {
			position: relative;
			cursor: pointer;
			min-width: 15.5em;
			height: 8.5em;
			margin-left: 0.5em;
			margin-right: 0.5em;
			@include box-shadow(0, 0.14em, 0.2em, 0em, rgba(black, 0.2));
			border-radius: 0.5em;

			@include transition('transform', 0.1);

			&:hover {
				@include scale(1.1);
			}
			&:first-child {
				margin-left: 1em;
			}
			&:last-child {
				margin-right: 1em;
			}

			.SavedDishes-currentDish {
				position: absolute;
				top: 0.4em;
				right: 0.4em;
				width: 2em;
				height: 2em;
				background-image: url(../../../assets/images/icons/icon-check.svg);
				background-size: 1.9em 1.9em;
			}
			
			.SavedDishes-savedDish {
				@include flex('center');
				height: 100%;
				width: 100%;

				background-color: $beigeLight;
				@include box-shadow-inset(0, -0.33em, 0, 0, $beigeDark);
				border-radius: 0.5em;

				.SavedDishes-dishImage {
					margin-top: 0.25em;
				}
			
				.SavedDishes-dishBottom {
					position: absolute;
					@include no-select();
					@include flex('center', 'center');
					bottom: 0;
					width: 100%;
					height: 2.4em;
					padding: 0.5em;
					padding-top: 0em;
					border-bottom-right-radius: 0.5em;
					border-bottom-left-radius: 0.5em;
					background-color: rgba($green, 0.4);

					span {
						color: $textDark;
						font-weight: 600;
					}
				}

				.SavedDishes-dishSmiley {
					position: absolute;
					background-size: 2.8em 2.8em;
					width: 2.9em;
					height: 2.9em;

					right: 1em;
					bottom: 1em;
	
					&.smiley-1 {
						background-image: url(../../../assets/images/icons/icon-smiley-saddest.svg);
					}
					&.smiley-2 {
						background-image: url(../../../assets/images/icons/icon-smiley-sad.svg);
					}
					&.smiley-3 {
						background-image: url(../../../assets/images/icons/icon-smiley.svg);
					}
					&.smiley-4 {
						background-image: url(../../../assets/images/icons/icon-smiley-glad.svg);
					}
					&.smiley-5 {
						background-image: url(../../../assets/images/icons/icon-smiley-gladdest.svg);
					}
				}
			}
		}
	}
	.SavedDishes-dishNavigation {
		.SavedDishes-dishScroll {
			position: absolute;
			cursor: pointer;
			z-index: 3;
			width: 3em;
			height: 10.75em;
			bottom: 0em;

			@include transition('transform', 0.15);

			&.right {
				background-image: url(../../../assets/images/icons/icon-arrow-2.svg);
				background-size: contain;
				width: 3.33em;
				height: 3.33em;
				right: -1em;
				margin: 0.5em;
				margin-bottom: 4em;

				&:hover {
					@include scale(1.2);
				}
			}
			&.left {
				background-image: url(../../../assets/images/icons/icon-arrow.svg);
				background-size: contain;
				width: 3.33em;
				height: 3.33em;
				left: -1em;
				margin: 0.5em;
				margin-bottom: 4em;

				&:hover {
					@include scale(1.2);
				}
			}
		}
	}
}