@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResetPopup {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($popupBlue, 0.5);
	@include flex('center', 'center');

	.ResetPopup-dialogue {
		background-color: $beigeLight;
		padding: 1.5em;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		width: 25em;
		border-radius: 0.5em;
		border-bottom-style: solid;
		border-bottom-color: $beigeDark;
		border-bottom-width: 0.24em;
		@include box-shadow(0, 0.14em, 0.3em, 0, rgba($black, 0.2));
		@include flex('space-between', 'center', 'column');

		.ResetPopup-dialogueText {
			@include flex('center', 'center');
			height: 5em;
			span {
				font-size: 1.2em;
				color: $textDark;
			}
		}
		.ResetPopup-dialogueNavigation {
			width: 100%;
			@include flex('space-between');
		}
	}
}
