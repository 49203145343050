@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieHandler {
	position: absolute;
	pointer-events: none;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	.CookieHandler-overlay {
		position: absolute;
		pointer-events: all;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($popupBlue, 0.5);

		@include flex('center', 'center');

		.CookieHandler-dialogue {
			background-color: $beigeLight;
			width: 34.76em;
			padding: 2em;
			border-bottom: solid;
			border-bottom-color: $beigeDark;
			border-bottom-width: 0.3em;
			border-radius: 0.5em;
			@include box-shadow(0, 0.14em, 0.2em, 0em, rgba(black, 0.2));
			@include flex('center', 'center', 'column');

			.CookieHandler-dialogueText {
				text-align: center;
				color: $textDark;
				margin-bottom: 2em;
			}

			.CookieHandler-dialogueButtons {
				@include flex('','','row');
				
				.CookieHandler-declineButton {
					margin-right: 0.9em;
				}
			}
		}
	}
	
	.CookieHandler-button {
		position: absolute;
		pointer-events: all;
		cursor: pointer;
		@include opacity(0.5);
		background-image: url(../../../assets/images/icons/icon-cookie.svg);
		background-size: 2.5em 2.5em;
		background-position: top center;
		width: 2.5em;
		height: 3em;
		bottom: -1.75em;
		left: 1.5em;

		@include transition('bottom', 0.5);

		&:hover {
			@include opacity(1);
			bottom: 0;
		}

		&.active {
			@include opacity(1);
			bottom: 0;
		}
	}
}
