@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameLogo {
	margin-top: 2em;
	background-image: url(../../../assets/images/logos/logo.svg);
	background-size: contain;
	width: 28.43em;
	height: 6.9em;
	@include transition('transform', 0.15);

	&:hover {
		@include scale(1.2);
		cursor: pointer;
	}
}