@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.InfoPopup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($popupBlue, 0.5);
	color: $textDark;
	z-index: 3;

	.InfoPopup-dialogueWrapper {
		position: relative;
		width: 47.6em;
		height: 38.1em;

		top: calc(50% - 38.1em / 2);
		left: calc(50% - 47.6em / 2);

		border-radius: 0.5em;
		background-color: $beigeLight;
		border-bottom-style: solid;
		border-bottom-color: $beigeDark;
		border-bottom-width: 0.24em;
		@include box-shadow(0, 0.14em, 0.3em, 0, rgba($black, 0.2));

		background-image: url(../../../assets/images/icons/icon-info-3.svg);
		background-size: 2.3em 2.3em;
		background-position: 1.8em 0.7em;

		padding: 2em;
		padding-top: 3.6em;

		@include flex('flex-start', '');
		line-height: 1.2em;

		@include openFromPoint();
		&.close {
			@include dissapearToPoint();
		}
		
		.InfoPopup-closeIcon {
			position: absolute;
			top: 1em;
			right: 1em;
			width: 2em;
			height: 2em;

			background-image: url(../../../assets/images/icons/icon-close.svg);
			background-size: contain;

			cursor: pointer;

			&:hover {
				@include scale(1.2);
			}
		}

		.InfoPopup-dialogue {
			width: 21.4em;

			&.center {
				@include flex('center','');
				width: 100%;
				position: absolute;
				bottom: 1em;
				margin-left: -2em;
			}

			&.left {
				padding-right: 1em;
			}

			.InfoPopup-textWrapper {
				margin-bottom: 1em;
				.InfoPopup-title {
					span {
						font-size: 1.14em;
						font-weight: 800;
					}
				}

				&.bold {
					span {
						font-weight: 800;
					}
				}

				.InfoPopup-colors {
					@include flex();
					margin-top: 0.25em;
					.InfoPopup-color {
						width: 0.9em;
						height: 0.9em;
						border-radius: 1em;
						margin-right: 0.4em;
						
						&.red {
							background-color: $red;
						}
						&.orange {
							background-color: $orange2;
						}
						&.yellow {
							background-color: $orange;
						}
						&.green {
							background-color: $green2Light
						}
						&.greenest {
							background-color: $green2;
						}
					}
				}
			}
		}
	}
}