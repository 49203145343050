@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Choice {
	cursor: pointer;
	width: 17.86em;
	height: 15.24em;
	border-radius: 0.5em;
	@include box-shadow(0, 0.14em, 0.2em, 0em, rgba(black, 0.2));

	.Choice-wrapper {
		width: 100%;
		height: 100%;
		border-radius: 0.5em;
		border-bottom: solid;
		border-bottom-color: $beigeDark;
		border-bottom-width: 0.3em;
		@include transition('all', 0.2);
		
		background-color: $beigeLight;

		.Choice-title {
			position: relative;
			@include no-select();
			@include flex('center', 'center');
			background-color: rgba($greenLight, 0.4);
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			height: 3.71em;

			.Choice-checkmark {
				position: absolute;
				right: 0.75em;
				top: 0.5em;
				width: 2em;
				height: 2em;
				background-image: url(../../../assets/images/icons/icon-check.svg);
				background-size: 1.9em 1.9em;
				background-position: center center;
			}

			span {
				font-size: 1.5em;
				font-weight: 800;
				color: $textDark;
				text-transform: capitalize;
			}
		}

		.Choice-content {
			@include flex('','','column');
			height: 11.5em;

			.Choice-contentText {
				@include flex('center', '');
				position: relative;
				width: 100%;
				padding-left: 0.5em;
				padding-right: 0.5em;

				span {
					text-align: center;
					color: $textDark;
				}
			}

			.Choice-contentImage {
				width: 100%;
				height: 100%;
				background-size: auto 6em;
				background-repeat: no-repeat;

				// RECIPES
				&.lasagna {
					background-image: url(../../../assets/images/icons/icon-lasagna.svg);
				}
				&.meatBalls {
					background-image: url(../../../assets/images/icons/icon-meatballs.svg);
				}
				&.curry {
					background-image: url(../../../assets/images/icons/icon-curry.svg);
				}

				// MISSIONS
				&.cheap {
					background-image: url(../../../assets/images/icons/icon-cheap.svg);
				}
				&.climate {
					background-image: url(../../../assets/images/icons/icon-climate.svg);
				}
				&.tasty {
					background-image: url(../../../assets/images/icons/icon-tasty.svg);
				}
			}
		}

		&:hover,
		&.clicked {
			@include box-shadow(0em, 0, 0, 0.4em, $green);
		}

		&:hover {
			@include scale(1.1);
		}
	}
}