@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Cooking {
	@include flex('space-between', 'center', 'column');
	height: 100%;
	width: 100%;
	overflow: hidden;

	.Cooking-recipeWrapper {
		@include flex('space-between', 'center', 'column');
		height: 100%;
		width: 100%;
		margin-right: 0.5em;
		.Cooking-title {
			@include no-select();
			span {
				font-size: 2.2em;
				font-weight: 800;
				text-transform: uppercase;
				color: $white;
			}
		}

		.Cooking-scrollNavigation {
			.Cooking-scroll {
				position: absolute;
				cursor: pointer;
				z-index: 3;
				width: 3em;
				height: 10.75em;
				bottom: 0em;

				@include transition('transform', 0.15);

				&.right {
					background-image: url(../../../assets/images/icons/icon-arrow-2.svg);
					background-size: contain;
					width: 3.33em;
					height: 3.33em;
					right: 0;
					margin: 0.5em;
					margin-bottom: 3.8em;

					&:hover {
						@include scale(1.2);
					}
				}
				&.left {
					background-image: url(../../../assets/images/icons/icon-arrow.svg);
					background-size: contain;
					width: 3.33em;
					height: 3.33em;
					left: 0;
					margin: 0.5em;
					margin-bottom: 3.8em;

					&:hover {
						@include scale(1.2);
					}
				}
			}
		}
		
		.Cooking-ingredientsChoicesWrapper {
			width: 100%;
			.Cooking-ingredientsGradient {
				position: absolute;
				pointer-events: none;
				bottom: 0;
				left: 0;
				right: 0;
				height: 14.23em;
				background-image: linear-gradient(
					to top,
					$blue2,
					rgba($blue2, 0.8),
					rgba($blue2, 0)
				);
			}
			
			.Cooking-ingredientsChoices {
				position: relative;
				@include flex();
				@include hide-scrollbar();
				overflow-x: auto;
				width: 100%;
				z-index: 2;
				pointer-events: none;

				.Cooking-ingredient {
					position: relative;
					@include flex('flex-end','','column');
					margin-left: 0.5em;
					margin-right: 0.5em;
					height: 25em;

					&:first-child {
						margin-left: 1.5em;
					}
					&:last-child {
						margin-right: 1.5em;
					}
				}
			}
		}
	}

	.Cooking-resultScreen {
		@include fade();
		position: absolute;
		top: 1.5em;
		right: 1.5em;
		padding: 0.75em;
		padding-top: 0.2em;
		border-radius: 0.5em;
		background-image: linear-gradient(
			to bottom,
			$greenLight,
			$green
		);

		border-bottom-color: $greenDark;
		border-bottom-style: solid;
		border-bottom-width: 0.33em;

		.Cooking-resultScreenTitle {
			margin-bottom: 0.5em;
			@include no-select();
			span {
				font-size: 1.1em;
				font-weight: 800;
				text-transform: capitalize;
				color: $white;
			}
		}
		.Cooking-pointCategories {
			.Cooking-pointCategory {
				position: relative;
			}
		}
	}

	.Cooking-pdfButton {
		position: absolute;
		bottom: 2.43em;
		padding-left: 1.6em;
		padding-right: 1.6em;
		right: 0em;

		a {
			text-decoration: none;
		}
	}
	
	.Cooking-cookButton {
		position: absolute;
		bottom: 3.1em;
		z-index: 1;
	}

	.Cooking-navigation {
		position: absolute;
		width: 100%;
		padding-left: 1.6em;
		padding-right: 1.6em;
		bottom: 2.43em;
		left: 0em;
	}

	.Cooking-dishListWrapper {
		position: absolute;
		width: 51.8em;
		height: 12em;
		bottom: 0em;
		padding: 0.75em;
		padding-top: 0;
	}
}