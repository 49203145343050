@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 800;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}	
	}

	&.basicGradient {
		background-image: linear-gradient(
			to bottom,
			$greenLight,
			$green
		);

		border-bottom-style: solid;
		border-bottom-color: $greenDark;
		border-bottom-width: 0.24em;

		@include box-shadow(0em, 0.14em, 0.3em, 0, rgba($black, 0.2));

		span {
			color: $white;
			text-transform: capitalize;
		}
	}

	&.small {
		width: 14.3em;
		height: 2.4em;
	}

	&.short {
		width: 10em;
	}

	&.red {
		background-image: linear-gradient(
			to bottom,
			$red,
			$redDark
		);
		border-bottom-color: $redDarker;
	}

	&.capitalize {
		span {
			text-transform: uppercase;
		}
	}

	&.bold {
		span {
			font-weight: 800;
		}
	}

	&.textSizeLarge {
		span {
			font-size: 1.14em;
		}
	}

	&.image {
		&.continue {
			&::after {
				content: '';
				height: calc(100% - 1em);
				width: 100%;
				position: absolute;
				background-image: url(../../../assets/images/icons/icon-continue.svg);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		&.back {
			&::after {
				content: '';
				height: calc(100% - 1em);
				width: 100%;
				position: absolute;
				background-image: url(../../../assets/images/icons/icon-back.svg);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&.left {
			&::after {
				background-position: left 0.8em top calc(50%);
			}
		}
		&.right {
			&::after {
				background-position: right 0.8em top calc(50%);
			}
		}
	}

	&.responsive {
		@include transition('transform', $seconds: 0.2);
		&:hover {
			@include scale(1.1);
		}

		&:active {
			background-image: linear-gradient(
				to bottom,
				$green,
				$greenDark
			);
			border-bottom-color: $greenDarker;

			background-color: rgba($black, 0.2);
		
			&.red {
				background-image: linear-gradient(
					to bottom,
					$redDark,
					$redDarker
				);
				border-bottom-color: $redDarkest;
	
				background-color: rgba($black, 0.2);
			}
		}
	}
}