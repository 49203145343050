@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResultBox {
	position: relative;

	padding: 0.5em;
	padding-top: 0.2em;
	margin-bottom: 0.5em;

	background-color: $beigeLight;
	border-radius: 0.5em;
		
	.ResultBox-infoIcon {
		pointer-events: auto;
		position: absolute;
		top: 0.3em;
		right: 0.3em;
		width: 1.4em;
		height: 1.4em;

		background-size: 1.2em 1.2em;
		background-image: url(../../../assets/images/icons/icon-info.svg);
		z-index: 2;
	}

	.ResultBox-title {
		@include no-select();
		pointer-events: none;
		span {
			font-size: 1.1em;
			font-weight: 700;
			text-transform: capitalize;
			color: $textDark;
		}
	}

	.ResultBox-result {
		position: relative;
		pointer-events: none;
		background-color: rgba($blue, 0.75);

		border-radius: 1em;
		@include flex('center', 'center');
		height: 1.3em;

		margin-top: 0.4em;
		border-style: solid;
		border-width: 0.1em;
		border-color: $blueLight;

		width: 16.67em;

		@include no-select();
		span {
			position: relative;
			color: $white;
			font-weight: 800;
			padding-bottom: 0.3em;
		}

		.ResultBox-resultFill {
			position: absolute;
			overflow: hidden;
			left: 0;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(
				to right,
				$red,
				$orange 25%,
				$green2);
			border-radius: 1em;

			@include box-shadow-inset(0, -0.2em, 0.1em, 0, rgba($black, 0.16));

			.ResultBox-fillReflection {
				width: calc(100% - 0.72em);
				height: 0.2em;
				margin-top: 0.2em;
				border-radius: 0.2em;
				background-color: rgba($white, 0.3);
				margin-left: 0.36em;
			}

			.ResultBox-fillBlock {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 30%;
				background-color: $blueGrey;

			}
		}
	}

	.ResultBox-pointResultPopupWrapper {
		position: absolute;
		top: -0.5em;
		left: -19.75em;
			
		.ResultBox-pointResultPopupArrow {
			position: absolute;
			background-image: url(../../../assets/images/dialogueArrowRight.svg);
			background-size: contain;
			width: 1.8em;
			height: 1.8em;
			top: 0.5em;
			right: -1.15em;
			z-index: 1;
		}
		.ResultBox-pointResultPopup {
			position: relative;
			@include flex('','','column');
			@include no-select();
			border-radius: 0.5em;
			border-bottom-style: solid;
			border-bottom-color: $beigeDark;
			background-color: $beigeLight;
			width: 18em;
			padding: 0.5em;
			z-index: 2;
			
			.ResultBox-pointResultPopupItemWrapper {
				@include flex('space-between', 'center', 'row');
				border-bottom-style: solid;
				border-bottom-width: 0.05em;
				border-bottom-color: $beigeDark;
				&:last-child {
					border-bottom-style: none;
				}

				.ResultBox-pointResultPopupItem {
					@include flex('', '', 'column');
					width: 14em;
					margin: 0.25em;
					.ResultBox-pointResultPopupItemTitle {
						width: 90%;
						line-height: 0.9em;

						span {
							font-size: 0.85em;
							color: $textDark;
						}
					}

					.ResultBox-pointResultItemText {
						width: 90%;
						line-height: 0.7em;

						&.wide {
							width: 100%;
						}

						span {
							font-size: 0.66em;
							color: $textDark;
							font-weight: 500;
						}
					}
				}
				.ResultBox-pointResultPopupItemValue {
					width: 0.9em;
					height: 0.9em;
					border-radius: 1em;
					&.co2-5,
					&.price-1,
					&.protein-1X,
					&.protein-1Y,
					&.protein-1,
					&.taste-1, &.taste-0 {
						background-color: $red;
					}
	
					&.taste-2,
					&.co2-4 {
						background-color: $orange2;
					}
	
					&.co2-3,
					&.price-2,
					&.protein-2,
					&.taste-3 {
						background-color: $orange;
					}
	
					&.co2-2,
					&.taste-4 {
						background-color: $green2Light
					}
	
					&.co2-1,
					&.price-3,
					&.protein-3,
					&.taste-5, &.taste-6 {
						background-color: $green2;
					}
				}
			}
		}
	}
}