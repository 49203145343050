@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.RecipeComponentList {
	&.isList {
		@include fade();
		margin-top: 0.75em;
		width: 17.62em;
	
		background-image: linear-gradient(
			to bottom,
			$greenLight,
			$green
		);
		padding: 0.5em;
		border-radius: 0.5em;

		border-bottom-color: $greenDark;
		border-bottom-style: solid;
		border-bottom-width: 0.33em;
	}

	.RecipeComponentList-recipeTitle {
		@include no-select();
		padding-left: 0.5em;
		margin-bottom: 0.5em;
		line-height: 1em;
		span {
			color: $white;
			font-weight: 800;
			font-size: 1.2em;
		}
	}
}