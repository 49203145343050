@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ChooseRecipe {
	@include flex('', 'center', 'column');

	.ChooseRecipe-logoAbsalon {
		position: absolute;
		width: 10em;
		height: 4em;
		top: 3.1em;
		left: 4em;
		background-image: url(../../../assets/images/logos/logo-absalon.svg);
		background-size: contain;
	}
	.ChooseRecipe-title {
		height: 5em;
		@include no-select();
		span {
			font-size: 2.2em;
			font-weight: 800;
			text-transform: uppercase;
			color: $white;
		}
	}
	.ChooseRecipe-recipes {
		@include flex();

		.ChooseRecipe-recipeButton {
			margin: 1em;

			&.isDisabled {
				@include opacity(0.6);
				pointer-events: none;
			}
		}
	}
	
	.ChooseRecipe-navigation {
		position: absolute;
		right: 2.4em;
		bottom: 2.43em;
	}
}